import { template as template_70b532b9d6b44dc4a4d1ceabe72070cb } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_70b532b9d6b44dc4a4d1ceabe72070cb(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
