import { template as template_da278862b5d140438d2da1314f50c50a } from "@ember/template-compiler";
const FKLabel = template_da278862b5d140438d2da1314f50c50a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
