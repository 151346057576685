import { template as template_666113b15623469c94a480919148ca88 } from "@ember/template-compiler";
const WelcomeHeader = template_666113b15623469c94a480919148ca88(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
