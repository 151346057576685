import { template as template_bb32bc2279df436f9e4cf053883f7ac6 } from "@ember/template-compiler";
const FKText = template_bb32bc2279df436f9e4cf053883f7ac6(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
